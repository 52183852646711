import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import logo from "../assets/images/logo.svg";
import logo2 from "../assets/images/yolohblue.svg";
import otpImg from "../assets/images/Yoloh_Yolo_transparent.png";
import Yolohlogo from "../assets/images/yoloh-dark-logo.svg";
import checkbox from "../assets/images/checkbox-active.svg";
import "./index.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Confirmed from "../assets/images/Yoloh_Yolo_transparent.png";
import kind_logo from "../assets/images/kind_logo.svg";
import jvnLogo from "../assets/images/jvn.png";
import EditIcon from "@mui/icons-material/Edit";
import {
  cognitoAttributeVerify,
  cognitoConfirmSignIn,
  cognitoCurrentUser,
  cognitoSignIn,
  cognitoSignUp,
  cognitoUpdateUserAttributes,
} from "../http/services/cognito.service";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  LinearProgress,
  Modal,
  Select,
  Stack,
} from "@mui/material";
import { useAuth } from "../context/AuthContext";
import { Controller, useForm } from "react-hook-form";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MuiCheckbox from "@mui/material/Checkbox";
import { handleEmailValidate, handleMobileValidate } from "../validations";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { environment } from "../environment/environment";
import { enqueueSnackbar } from "notistack";
import {
  getLeadDetailsByLeadUUID,
  getUserDetailsByLeadUUIDPayloadTemplate,
  userRegisterPayloadTemplate,
} from "../http/services/api-payload-prepare";
import {
  getUserLeadUUID,
  insurancePreFillLead,
  openApiEndPoint,
} from "../http/services/lead.service";
import axios from "axios";
import { useLocalStorage } from "../http/services/local-storage";

const Login = ({ setLoginModal }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getItem, setItem, removeItem } = useLocalStorage();
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [error, setError] = useState(false);
  const [showKey, setShowKey] = useState("LOGIN");
  const [registerFlowStarted, setRegisterFlowStarted] = useState(false);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [lenderUserWarning, setLenderUserWarning] = useState({
    isOpen: false,
    message: null,
  });
  const { login, logout, setAuthUser } = useAuth();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [uuidLoader, setUuidLoader] = useState(false);
  const [termAndCondition, setTermsAndCondition] = useState(false);
  const [value, setValue] = React.useState(0);
  const [countryPrefixes] = useState(
    JSON.parse(environment.country_prefix.available_prefix)
  );
  const [currentCountryPrefix, setCurrentCountryPrefix] = useState(
    environment.country_prefix.default_prefix
  );
  const screenPath = location?.pathname?.split("/");
  const getVerifyInsurance = async () => {
    const payload = getLeadDetailsByLeadUUID(screenPath[3]);
    insurancePreFillLead(payload)
      .then((data) => {
        if (data.code === 229 && data?.lead_details?.email) {
          if (data?.lead_details?.homeLeadSequenceId) {
            setItem(
              "homeLeadSequenceId",
              data?.lead_details?.homeLeadSequenceId
            );
          }
          if (data?.lead_details?.autoAndHomeLeadSequenceId) {
            setItem(
              "autoAndHomeLeadSequenceId",
              data?.lead_details?.autoAndHomeLeadSequenceId
            );
          }
          if (
            lenderUserWarning?.onlyEmailExists ||
            lenderUserWarning?.userExistsWithDifferentCombination
          ) {
            setLenderUserWarning({
              ...lenderUserWarning,
              isOpen: true,
              message: lenderUserWarning?.apiMassage,
              primaryBtn: "Ok",
              secondaryBtn: null,
              lead_details: data?.lead_details,
            });
          } else if (lenderUserWarning?.onlyPhoneExists) {
            setLenderUserWarning({
              ...lenderUserWarning,
              isOpen: true,
              message: lenderUserWarning?.apiMassage,
              primaryBtn: "Ok",
              secondaryBtn: null,
              onlyPhoneExists: false,
              lead_details: data?.lead_details,
            });
          } else {
            navigate("/", {
              state: {
                uuid: screenPath[3],
                type: screenPath[1],
                lender: screenPath[2],
                lead_details: data?.lead_details,
                leadCreated: lenderUserWarning?.leadCreated,
              },
            });
            setLoginModal(false);
          }
          setRequestInProgress(false);
          setOtp("");
          setEmail("");
          setShowKey("LOGIN");
        }
      })
      .catch((err) => {});
    // .finally(() => dispatch(loaderEnd()));
  };
  const getUserLeadDetailsUUID = async () => {
    setUuidLoader(true);
    setShowKey("OTP");
    setValue(1);
    const fetchUserLeadUUIDPayload = getUserDetailsByLeadUUIDPayloadTemplate(
      screenPath[3]
    );
    // const instance = axios.create();
    // let response = await instance
    //   .post(environment.api_open_endpoint + "/lender", fetchUserLeadUUIDPayload)
    //   .catch(() => {
    //     return [];
    //   });
    try {
      const response = await fetch(
        environment.api_open_endpoint + "/open-api",
        {
          method: "POST",
          headers: {},
          body: JSON.stringify(fetchUserLeadUUIDPayload),
        }
      );
      if (response?.status === 200) {
        const responseData = await response.json();
        if (responseData?.email) {
          localStorage?.clear();
          setAuthUser({});
          if (
            responseData?.onlyEmailExists ||
            responseData?.userExistsWithDifferentCombination
          ) {
            setSeconds(90);
            setUsername(responseData?.email);
            signInOtpRequest(responseData?.email);
            if (responseData?.leadCreated) {
              setItem("leadCreated", responseData?.leadCreated);
            }
            setLenderUserWarning({
              ...lenderUserWarning,
              ...responseData,
              apiMassage: responseData?.message,
            });
          } else if (responseData?.onlyPhoneExists) {
            setLenderUserWarning({
              ...lenderUserWarning,
              ...responseData,
              isOpen: true,
              message: `The Mobile number ${responseData?.phoneNumber?.replace(
                /.(?=.{4,9}$)/g,
                "*"
              )} is already exist? Do you want to use the same mobile number?`,
              primaryBtn: "Yes",
              apiMassage: responseData?.message,
              secondaryBtn: "No",
            });
          } else {
            setSeconds(90);
            setUsername(responseData?.email);
            signInOtpRequest(responseData?.email);
            setLenderUserWarning({
              ...lenderUserWarning,
              ...responseData,
              apiMassage: responseData?.message,
            });
          }
          setUuidLoader(false);
        } else {
          setLoginModal(false);
          setUuidLoader(false);
        }
      }
    } catch (error) {
      console.error(error, "error");
    }
  };
  useState(() => {
    if (screenPath[1] === "lender_user" || screenPath[1] === "ivs") {
      removeItem("homeLeadSequenceId");
      removeItem("autoAndHomeLeadSequenceId");
      removeItem("leadCreated");
      getUserLeadDetailsUUID();
    }
  }, [location?.pathname]);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        onChange={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  const navigateCreateAccount = () => {
    setLoginModal(false);
    setShowKey("LOGIN");
    // navigate("/create-account");
  };
  const { handleSubmit, trigger, reset, control /*formState: {errors}*/ } =
    useForm();

  const resetLoginForm = () => {
    reset({
      phone_number: "",
      email: "",
    });
    setUsername("");
    setEmail("");
    setOtp("");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    resetLoginForm();
  };

  const handelLenderUserWarning = (key) => {
    if (key === "Ok" || key === "Yes") {
      if (
        lenderUserWarning?.onlyEmailExists ||
        lenderUserWarning?.userExistsWithDifferentCombination
      ) {
        navigate("/", {
          state: {
            uuid: screenPath[3],
            type: screenPath[1],
            lender: screenPath[2],
            lead_details: lenderUserWarning?.lead_details,
            leadCreated: lenderUserWarning?.leadCreated,
          },
        });
        setLoginModal(false);
      } else if (lenderUserWarning?.onlyPhoneExists) {
        setShowKey("ENTERPHONE");
      } else {
        navigate("/", {
          state: {
            uuid: screenPath[3],
            type: screenPath[1],
            lender: screenPath[2],
            lead_details: lenderUserWarning?.lead_details,
            leadCreated: lenderUserWarning?.leadCreated,
          },
        });
        setLoginModal(false);
      }
    } else {
      if (lenderUserWarning?.onlyPhoneExists) {
        setShowKey("CHANGEPHONE");
      }
    }
    setLenderUserWarning({ ...lenderUserWarning, isOpen: false });
  };

  const onCloseLenderUserWarning = () => {
    setLenderUserWarning({ isOpen: false, message: null });
  };
  const signInOtpRequest = (usernameCalculated) => {
    cognitoSignIn(usernameCalculated, value)
      .then((res) => {
        setCognitoUser(res);
        setRegisterFlowStarted(false);
        setShowKey("OTP");
        setSeconds(90);
      })
      .catch((err) => {
        let errData = err;
        if (
          errData.code == "UserLambdaValidationException" &&
          errData.message.includes("phone_number already exists")
        ) {
          setError("Phone number is already exist, please login.");
          setTimeout(() => {
            setError(null);
          }, 3000);
        } else if (
          errData.code == "UserLambdaValidationException" &&
          errData.message.includes("user does not exists")
        ) {
          setError("User does not exists, please Sign Up.");
          setTimeout(() => {
            setError(null);
          }, 3000);
        } else {
          setError(errData.message);
          setTimeout(() => {
            setError(null);
          }, 3000);
        }
      })
      .finally(() => {
        setRequestInProgress(false);
      });
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const validateChar = (value, index) => {
    const NUMERIC_PLUS_REGEX = /^[0-9]+$/;
    return value ? NUMERIC_PLUS_REGEX.test(value) : true;
  };

  const setUsernameFieldValue = (event) => {
    setUsername(event.target.value);
  };

  const setEmailFieldValue = (event) => {
    setEmail(event.target.value);
  };

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(90);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const handelCreateAccount = () => {
    cognitoSignUp(currentCountryPrefix + username)
      .then((res) => {
        cognitoSignIn(currentCountryPrefix + username, value)
          .then((res) => {
            setCognitoUser(res);
            setRequestInProgress(false);
            setShowKey("OTP");
            setSeconds(90);
          })
          .catch((err) => {
            setRequestInProgress(false);
            setRegisterFlowStarted(false);
            let errData = err;
            if (
              errData.code == "UserLambdaValidationException" &&
              errData.message.includes("phone_number already exists")
            ) {
              setError("Phone number is already exist, please login.");
              setTimeout(() => {
                setError(null);
              }, 3000);
            } else if (
              errData.code == "UserLambdaValidationException" &&
              errData.message.includes("user does not exists")
            ) {
              setError("User does not exists, please Sign Up.");
              setTimeout(() => {
                setError(null);
              }, 3000);
            } else {
              setError(errData.message);
              setTimeout(() => {
                setError(null);
              }, 3000);
            }
          });
      })
      .catch((err) => {
        setRequestInProgress(false);
        setRegisterFlowStarted(false);
        let errData = err;
        if (
          errData.code == "UserLambdaValidationException" &&
          errData.message.includes("phone_number already exists")
        ) {
          setError("Phone number is already exist, please login.");
          setTimeout(() => {
            setError(null);
          }, 3000);
        } else if (
          errData.code == "UserLambdaValidationException" &&
          errData.message.includes("user does not exists")
        ) {
          setError("User does not exists, please Sign Up.");
          setTimeout(() => {
            setError(null);
          }, 3000);
        } else {
          setError(errData.message);
          setTimeout(() => {
            setError(null);
          }, 3000);
        }
      });
  };
  return (
    <>
      <div className="popupBg">
        <>
          <div className="logImgMain">
            <div className="popupContain">
              <img width="200px" alt="Angular Logo" src={logo} />
              <img
                alt="Angular Logo"
                className="illu"
                src={
                  showKey !== "ENTEREMAIL" || showKey !== "EMAIlOTP"
                    ? otpImg
                    : Confirmed
                }
              />
            </div>
          </div>
          <IconButton
            aria-label="delete"
            classes={{ root: "closeLoginModalButton" }}
            onClick={() => setLoginModal(false)}
          >
            <CloseOutlinedIcon />
          </IconButton>

          {/* LOGIN  */}
          {showKey === "LOGIN" && (
            <div className="formContainMain h-100">
              <form className="overflow">
                <h1
                  style={{ maxWidth: "90%", marginBottom: "50px" }}
                  className="res-modal-title"
                >
                  Log in to your account
                </h1>
                {/* <p>Hello, Welcome back to account!</p> */}
                <div className="tabs">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    color="primary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Phone Number" {...a11yProps(0)} />
                    <Tab label="Email Id" {...a11yProps(1)} />
                  </Tabs>

                  {value == 0 && (
                    <div className="mt-20">
                      <FormControl
                        style={{ width: "20%", marginRight: "10px" }}
                      >
                        <InputLabel id="prefix-login-label">Code</InputLabel>
                        <Select
                          disabled={
                            environment.country_prefix.is_country === "false"
                          }
                          labelId="prefix-login-label"
                          id="prefix-login-label-select"
                          label="Prefix"
                          value={currentCountryPrefix}
                          onChange={(event) =>
                            setCurrentCountryPrefix(event.target.value)
                          }
                        >
                          {countryPrefixes.map((code) => (
                            <MenuItem value={code} key={code}>
                              {code}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Controller
                        name="phone_number"
                        control={control}
                        rules={{
                          required: "Phone is required.",
                          validate: (value) =>
                            handleMobileValidate(value)
                              ? null
                              : "Please enter valid Phone number.",
                        }}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            style={{ width: "75%", margin: 0 }}
                            id="phone_number"
                            name="phone_number"
                            label="Phone Number"
                            variant="outlined"
                            margin="normal"
                            color="secondary"
                            type="text"
                            value={value}
                            error={!!error}
                            placeholder="Enter your phone number"
                            onKeyDown={(event) => {
                              const NUMERIC_PLUS_REGEX = /^[0-9]+$/;
                              if (event.key === "Tab") {
                              } else if (
                                !NUMERIC_PLUS_REGEX.test(event.key) &&
                                !event.ctrlKey &&
                                event.code &&
                                event.code != "Backspace" &&
                                !event.code.includes("Arrow") &&
                                !event.code.includes("Delete")
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onBlur={handleSubmit()}
                            onChange={(event) => {
                              onChange(event);
                              setUsernameFieldValue(event);
                            }}
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{
                              classes: {
                                focused: "hello-world",
                                root: "world",
                              },
                            }}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </div>
                  )}
                  {value == 1 && (
                    <div className="mt-20">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: "Email is required.",
                          validate: (value) =>
                            handleEmailValidate(value)
                              ? null
                              : "Please enter valid email.",
                        }}
                        defaultValue=""
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            id="email"
                            name="email"
                            label="Email Id"
                            variant="outlined"
                            margin="normal"
                            color="secondary"
                            value={value}
                            error={!!error}
                            placeholder="Enter your email id"
                            onBlur={handleSubmit()}
                            onChange={(event) => {
                              onChange(event);
                              setUsernameFieldValue(event);
                            }}
                            fullWidth
                            inputProps={{ tabIndex: 1 }}
                            InputLabelProps={{
                              classes: {
                                focused: "hello-world",
                                root: "world",
                              },
                            }}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </div>
                  )}

                  {/*<TabPanel value={value} index={0} className="tabView">*/}
                  {/*</TabPanel>*/}
                  {/*<TabPanel value={value} index={1} className="tabView">*/}
                  {/*</TabPanel>*/}

                  {error && <p className="error_block">{error}</p>}
                  {requestInProgress && (
                    <p className="please_wait_block">
                      Please wait &nbsp; &nbsp;{" "}
                      <CircularProgress color="inherit" size={30} />
                    </p>
                  )}

                  {!requestInProgress && (
                    <button
                      className="button-primary otp-button"
                      disabled={!username}
                      type="submit"
                      onClick={handleSubmit(() => {
                        setRequestInProgress(true);
                        let usernameCalculated =
                          value == 1
                            ? username
                            : currentCountryPrefix + username;
                        signInOtpRequest(usernameCalculated);
                      })}
                    >
                      Get One Time Passcode (OTP)
                    </button>
                  )}

                  <h3 className="account-link">
                    New to YOLOH?{" "}
                    <a
                      href="#"
                      onClick={() => {
                        if (value === 0) {
                          setUsername(username);
                        } else {
                          setEmail(username);
                        }
                        setValue(0);
                        setShowKey("CREATE");
                      }}
                    >
                      Sign Up
                    </a>
                  </h3>
                </div>
              </form>
            </div>
          )}
          {/*  */}
          {/* OTP VERIFICATION */}
          {showKey === "OTP" && (
            <div className="formContainMain h-100">
              <form className="overflow">
                {/* {screenPath?.length > 0 &&
                  ["lender_user", "ivs"].includes(screenPath[1]) && (
                    <div
                      style={{
                        marginBottom: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div></div>
                      <img
                      width="114px"
                      height="35px"
                      alt="Angular Logo"
                      src={Yolohlogo}
                      className="logoMain"
                    />
                      <img
                        // width="61px"
                        // height="33px"
                        alt="Angular Logo"
                        src={screenPath[2] === "kind" ? kind_logo : jvnLogo}
                        className="logoMain"
                      />
                    </div>
                  )} */}
                <div>
                  <h1>Verification Code</h1>
                  <p
                    style={{
                      display: "inline",
                      margin: 0,
                      alignItems: "center",
                    }}
                  >
                    Please enter the One Time Passcode (OTP) sent to&nbsp;{" "}
                    {uuidLoader && (
                      <CircularProgress color="inherit" size={25} />
                    )}
                    <strong>
                      {value == 0 ? currentCountryPrefix + username : username}
                    </strong>
                  </p>

                  <div className="otp-section">
                    <div className="OtpFildMain">
                      <MuiOtpInput
                        value={otp}
                        length={6}
                        validateChar={validateChar}
                        onChange={(newValue) => setOtp(newValue)}
                      />
                    </div>

                    {error && <p className="error_block">{error}</p>}

                    {requestInProgress && (
                      <p className="please_wait_block">
                        Please wait &nbsp; &nbsp;{" "}
                        <CircularProgress color="inherit" size={30} />
                      </p>
                    )}
                    {screenPath?.length > 0 &&
                      ["lender_user", "ivs"].includes(screenPath[1]) &&
                      !requestInProgress && (
                        <div className="d-flex">
                          <MuiCheckbox
                            checked={termAndCondition}
                            onChange={(value) => {
                              setError(null);
                              setTermsAndCondition(value.target.checked);
                            }}
                            label="CheckCircleIcon"
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                          {/*<img alt="Angular Logo" className="illu" src={checkbox} />*/}
                          <p className="font-14 mt-20 mb-20">
                            I accept the{" "}
                            <a href="/terms" target="_blank">
                              <strong style={{ textTransform: "capitalize" }}>
                                terms of use
                              </strong>
                            </a>{" "}
                            and
                            <a href="/privacy-policy" target="_blank">
                              <strong style={{ textTransform: "capitalize" }}>
                                {" "}
                                privacy policy
                              </strong>
                            </a>
                          </p>
                        </div>
                      )}
                    {!requestInProgress && (
                      <>
                        <h3 className="resend mt-0">
                          {seconds > 0 || minutes > 0 ? (
                            <p>
                              Time Remaining To Resend <br /> One Time Passcode
                              (OTP) : {minutes < 10 ? `0${minutes}` : minutes}:
                              {seconds < 10 ? `0${seconds}` : seconds}
                            </p>
                          ) : (
                            <>
                              <p>
                                Didn't receive code? <br />
                                <a
                                  href="#"
                                  onClick={() => {
                                    setRequestInProgress(true);
                                    setSeconds(90);
                                    let usernameCalculated =
                                      value == 0
                                        ? currentCountryPrefix + username
                                        : username;
                                    cognitoSignIn(usernameCalculated, value)
                                      .then((res) => {
                                        setCognitoUser(res);
                                      })
                                      .finally(() => {
                                        setRequestInProgress(false);
                                      });
                                  }}
                                  className="span-primary"
                                  style={{ color: "black" }}
                                >
                                  Resend One Time Passcode (OTP)
                                </a>
                              </p>
                            </>
                          )}
                        </h3>
                        <button
                          className="otpButton button-primary"
                          disabled={!otp || otp?.trim().length < 6}
                          type="submit"
                          onClick={(e) => {
                            e?.preventDefault();
                            if (
                              screenPath?.length > 0 &&
                              ["lender_user", "ivs"].includes(screenPath[1]) &&
                              !termAndCondition
                            ) {
                              setError("You must agree to term & condition.");
                              setTimeout(() => {
                                setError(null);
                              }, 3000);
                            } else {
                              setRequestInProgress(true);
                              cognitoConfirmSignIn(cognitoUser, otp)
                                .then(async (user) => {
                                  setSeconds(0);
                                  setRequestInProgress(true);
                                  if (
                                    user.attributes &&
                                    user.attributes.phone_number
                                  ) {
                                    if (registerFlowStarted) {
                                      setOtp("");
                                      setSeconds(90);
                                      setShowKey("EMAILOTP");
                                      cognitoUpdateUserAttributes(cognitoUser, {
                                        email: email,
                                      })
                                        .then((res) => {})
                                        .finally(() => {
                                          setRequestInProgress(false);
                                        });
                                    } else {
                                      if (
                                        user.attributes.email_verified == false
                                      ) {
                                        setShowKey("ENTEREMAIL");
                                        setRequestInProgress(false);
                                      } else if (
                                        !(
                                          user?.attributes?.email &&
                                          user?.attributes?.email?.length > 0
                                        )
                                      ) {
                                        setShowKey("ENTEREMAIL");
                                        setRequestInProgress(false);
                                      } else {
                                        await login(user);
                                        if (
                                          screenPath?.length > 0 &&
                                          ["lender_user", "ivs"].includes(
                                            screenPath[1]
                                          )
                                        ) {
                                          await getVerifyInsurance();
                                        } else {
                                          setRequestInProgress(false);
                                          setOtp("");
                                          setEmail("");
                                          setShowKey("LOGIN");
                                          navigate("/");
                                          setLoginModal(false);
                                        }
                                        // navigate(0);
                                      }
                                    }
                                  } else {
                                    setError("Invalid One Time Passcode.");
                                    setOtp("");
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setRequestInProgress(false);
                                  }
                                })
                                .catch((err) => {
                                  if (err?.code === "CodeMismatchException") {
                                    setError("Invalid One Time Passcode.");
                                    setOtp("");
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setRequestInProgress(false);
                                  } else {
                                    setError("Please try to login again.");
                                    resetLoginForm();
                                    setShowKey("LOGIN");
                                    setTimeout(() => {
                                      setError(null);
                                    }, 5000);
                                    setRequestInProgress(false);
                                  }
                                });
                            }
                          }}
                        >
                          Verify One Time Passcode (OTP)
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </form>
            </div>
          )}
          {showKey === "ENTEREMAIL" && (
            <div className="formContainMain h-100">
              <form className="overflow">
                <h1>Enter Your Email</h1>
                <p>Please enter your Email to receive the one-time password</p>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is required.",
                    validate: (value) =>
                      handleEmailValidate(value)
                        ? null
                        : "Please enter valid email.",
                  }}
                  defaultValue={email}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <TextField
                      id="email"
                      name="email"
                      label="Email Id"
                      variant="outlined"
                      margin="normal"
                      color="secondary"
                      value={value}
                      error={!!error}
                      placeholder="Enter your email id"
                      onBlur={handleSubmit()}
                      onChange={(event) => {
                        onChange(event);
                        setEmailFieldValue(event);
                      }}
                      fullWidth
                      inputProps={{ tabIndex: 1 }}
                      InputLabelProps={{
                        classes: { focused: "hello-world", root: "world" },
                      }}
                      helperText={error ? error.message : null}
                    />
                  )}
                />

                {requestInProgress && (
                  <p className="please_wait_block">
                    Please wait &nbsp; &nbsp;{" "}
                    <CircularProgress color="inherit" size={30} />
                  </p>
                )}

                {!requestInProgress && (
                  <button
                    className="button-primary otp-button mt-156"
                    disabled={!email}
                    type="submit"
                    onClick={handleSubmit(() => {
                      setRequestInProgress(true);
                      cognitoUpdateUserAttributes(cognitoUser, { email: email })
                        .then((res) => {
                          setRequestInProgress(false);
                          setOtp("");
                          setShowKey("EMAILOTP");
                          setSeconds(90);
                        })
                        .finally(() => {
                          setRequestInProgress(false);
                        });
                    })}
                  >
                    Get One Time Passcode (OTP)
                  </button>
                )}
              </form>
            </div>
          )}
          {showKey === "EMAILOTP" && (
            <div className="formContainMain h-100">
              <div className="overflow">
                <h1>Verification Code</h1>
                <p>
                  Please enter the One Time Passcode (OTP) sent to{" "}
                  <strong>
                    {email} &nbsp;{" "}
                    <EditIcon
                      fontSize="small"
                      onClick={() => {
                        setShowKey("ENTEREMAIL");
                      }}
                    />
                  </strong>
                </p>
                <div className="otp-section">
                  <div className="OtpFildMain">
                    <MuiOtpInput
                      value={otp}
                      length={6}
                      validateChar={validateChar}
                      onChange={(newValue) => setOtp(newValue)}
                    />
                  </div>

                  {error && <p className="error_block">{error}</p>}

                  {requestInProgress && (
                    <p className="please_wait_block">
                      Please wait &nbsp; &nbsp;{" "}
                      <CircularProgress color="inherit" size={30} />
                    </p>
                  )}

                  {!requestInProgress && (
                    <>
                      <h3 className="resend">
                        {seconds > 0 || minutes > 0 ? (
                          <p>
                            Time Remaining To Resend <br /> One Time Passcode
                            (OTP) : {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </p>
                        ) : (
                          <>
                            <p>
                              Didn't receive code? <br />
                              <a
                                href="#"
                                onClick={() => {
                                  setRequestInProgress(true);
                                  setSeconds(90);
                                  cognitoUpdateUserAttributes(cognitoUser, {
                                    email: email,
                                  })
                                    .then((res) => {
                                      cognitoCurrentUser().then((user) => {
                                        // setCognitoUser(user)
                                      });
                                    })
                                    .finally(() => {
                                      setRequestInProgress(false);
                                    });
                                }}
                                className="span-primary"
                                style={{ color: "black" }}
                              >
                                Resend One Time Passcode (OTP)
                              </a>
                            </p>
                          </>
                        )}
                      </h3>
                      <button
                        className="otpButton button-primary"
                        onClick={() => {
                          setRequestInProgress(true);
                          cognitoAttributeVerify(cognitoUser, "email", otp)
                            .then((res) => {
                              cognitoCurrentUser().then(async (res) => {
                                await login(res);
                                setLoginModal(false);
                                setRequestInProgress(false);
                                setOtp("");
                                setEmail("");
                                setShowKey("LOGIN");
                                navigate("/");
                                // navigate(0);
                              });
                            })
                            .catch((err) => {
                              let errData = err;
                              if (
                                errData.code == "AliasExistsException" &&
                                errData.message.includes(
                                  "email already exists."
                                )
                              ) {
                                setError(
                                  "Email is already exists with other account, please try with different email."
                                );
                                enqueueSnackbar(
                                  "Email is already exists with other account, please try with different email.",
                                  {
                                    variant: "error",
                                  }
                                );
                                setSeconds(90);
                                setShowKey("ENTEREMAIL");
                                setTimeout(() => {
                                  setError(null);
                                }, 5000);
                              } else if (
                                errData.code == "CodeMismatchException" &&
                                errData.message.includes(
                                  "Invalid One Time Passcode"
                                )
                              ) {
                                setError("Invalid One Time Passcode (OTP).");
                                setSeconds(90);
                                setTimeout(() => {
                                  setError(null);
                                }, 5000);
                              } else if (errData.message) {
                                setError(errData.message);
                              } else {
                                setError("Invalid One Time Passcode (OTP).");
                              }
                              setOtp("");
                              setRequestInProgress(false);
                              setTimeout(() => {
                                setError(null);
                              }, 5000);
                            });
                        }}
                      >
                        Verify One Time Passcode (OTP)
                      </button>
                    </>
                  )}
                </div>
                {/*<div className="stap-main">*/}
                {/*  <div className="stap"></div>*/}
                {/*  <div className="stap stap-active"></div>*/}
                {/*  <div className="stap"></div>*/}
                {/*</div>*/}
              </div>
            </div>
          )}
          {/* CREATE ACCOUNT */}
          {showKey === "CREATE" && (
            <div className="formContainMain h-100">
              <form className="overflow">
                <div>
                  <h1 className="res-create-modal-title">Create Account</h1>
                  <p>
                    Please enter your Phone Number and Email to create an
                    account.
                  </p>
                  <div className="mt-50">
                    <div
                      className="verify-account"
                      style={{ gap: "10px", width: "100%", display: "flex" }}
                    >
                      <FormControl style={{ width: "30%" }}>
                        <InputLabel id="prefix-register-label">Code</InputLabel>
                        <Select
                          labelId="prefix-register-label"
                          id="prefix-register-label-select"
                          label="Prefix"
                          disabled={
                            environment.country_prefix.is_country === "false"
                          }
                          value={currentCountryPrefix}
                          onChange={(event) =>
                            setCurrentCountryPrefix(event.target.value)
                          }
                        >
                          {countryPrefixes.map((code) => (
                            <MenuItem value={code} key={code}>
                              {code}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Controller
                        name="phone_number"
                        control={control}
                        rules={{
                          required: "Phone is required.",
                          validate: (value) =>
                            handleMobileValidate(value)
                              ? null
                              : "Please enter valid Phone number.",
                        }}
                        defaultValue={username}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            style={{ margin: 0 }}
                            id="phone_number"
                            name="phone_number"
                            label="Phone Number"
                            variant="outlined"
                            margin="normal"
                            color="secondary"
                            type="text"
                            value={value}
                            error={!!error}
                            placeholder="Enter your phone number"
                            onKeyDown={(event) => {
                              const NUMERIC_PLUS_REGEX = /^[0-9]+$/;
                              if (event.key === "Tab") {
                              } else if (
                                !NUMERIC_PLUS_REGEX.test(event.key) &&
                                !event.ctrlKey &&
                                event.code &&
                                event.code != "Backspace" &&
                                !event.code.includes("Arrow") &&
                                !event.code.includes("Delete")
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onBlur={handleSubmit(() => {})}
                            onChange={(event) => {
                              onChange(event);
                              setUsernameFieldValue(event);
                            }}
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                            InputLabelProps={{
                              classes: {
                                focused: "hello-world",
                                root: "world",
                              },
                            }}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </div>
                    <div className="verify-account">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: "Email is required.",
                          validate: (value) =>
                            handleEmailValidate(value)
                              ? null
                              : "Please enter valid email.",
                        }}
                        defaultValue={email}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <TextField
                            id="email"
                            name="email"
                            label="Email Id"
                            variant="outlined"
                            margin="normal"
                            color="secondary"
                            value={value}
                            error={!!error}
                            placeholder="Enter your email id"
                            onBlur={handleSubmit()}
                            onChange={(event) => {
                              onChange(event);
                              setEmailFieldValue(event);
                            }}
                            fullWidth
                            // inputProps={{ tabIndex: 1 }}
                            InputLabelProps={{
                              classes: {
                                focused: "hello-world",
                                root: "world",
                              },
                            }}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </div>
                    {error && <p className="error_block">{error}</p>}

                    {requestInProgress && (
                      <p className="please_wait_block">
                        Please wait &nbsp; &nbsp;{" "}
                        <CircularProgress color="inherit" size={30} />
                      </p>
                    )}
                    {!requestInProgress && (
                      <>
                        <div className="d-flex">
                          <MuiCheckbox
                            checked={termAndCondition}
                            onChange={(value) => {
                              setError(null);
                              setTermsAndCondition(value.target.checked);
                            }}
                            label="CheckCircleIcon"
                            icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<CheckCircleIcon />}
                          />
                          {/*<img alt="Angular Logo" className="illu" src={checkbox} />*/}
                          <p className="font-14 mt-20 mb-20">
                            I accept the{" "}
                            <a href="/terms" target="_blank">
                              <strong style={{ textTransform: "capitalize" }}>
                                terms of use
                              </strong>
                            </a>{" "}
                            and
                            <a href="/privacy-policy" target="_blank">
                              <strong style={{ textTransform: "capitalize" }}>
                                {" "}
                                privacy policy
                              </strong>
                            </a>
                          </p>
                        </div>
                        <p className="font-12 mb-20 mt-0">
                          By creating an account, you are agreeing to the YOLOH
                          terms and conditions and YOLOH privacy policy. Message
                          and data rates may apply when registering with a phone
                          number
                        </p>
                        <button
                          className="otpButton button-primary"
                          type="submit"
                          onClick={handleSubmit(() => {
                            if (termAndCondition) {
                              setRequestInProgress(true);
                              setRegisterFlowStarted(true);
                              handelCreateAccount();
                            } else {
                              setError("You must agree to term & condition.");
                              setTimeout(() => {
                                setError(null);
                              }, 3000);
                            }
                          })}
                        >
                          Create Account
                        </button>
                      </>
                    )}

                    <h3 className="account-link">
                      Already have an Account?{" "}
                      <a href="#" onClick={() => setShowKey("LOGIN")}>
                        Sign in
                      </a>{" "}
                      now
                    </h3>
                  </div>
                </div>
              </form>
              {/*<div className="stap-main">*/}
              {/*  <div className="stap stap-active"></div>*/}
              {/*  <div className="stap"></div>*/}
              {/*  <div className="stap"></div>*/}
              {/*</div>*/}
            </div>
          )}
          {showKey === "CHANGEPHONE" && (
            <div className="formContainMain h-100">
              <form className="overflow">
                <h1 style={{ maxWidth: "90%" }} className="res-modal-title">
                  Enter New Phone Number
                </h1>
                <div className="tabs">
                  <div className="mt-20">
                    <FormControl style={{ width: "20%", marginRight: "10px" }}>
                      <InputLabel id="prefix-login-label">Code</InputLabel>
                      <Select
                        disabled={
                          environment.country_prefix.is_country === "false"
                        }
                        labelId="prefix-login-label"
                        id="prefix-login-label-select"
                        label="Prefix"
                        value={currentCountryPrefix}
                        onChange={(event) =>
                          setCurrentCountryPrefix(event.target.value)
                        }
                      >
                        {countryPrefixes.map((code) => (
                          <MenuItem value={code} key={code}>
                            {code}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Controller
                      name="phone_number"
                      control={control}
                      rules={{
                        required: "Phone is required.",
                        validate: (value) =>
                          handleMobileValidate(value)
                            ? null
                            : "Please enter valid Phone number.",
                      }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          style={{ width: "75%", margin: 0 }}
                          id="phone_number"
                          name="phone_number"
                          label="Phone Number"
                          variant="outlined"
                          margin="normal"
                          color="secondary"
                          type="text"
                          value={value}
                          error={!!error}
                          placeholder="Enter your phone number"
                          onKeyDown={(event) => {
                            const NUMERIC_PLUS_REGEX = /^[0-9]+$/;
                            if (event.key === "Tab") {
                            } else if (
                              !NUMERIC_PLUS_REGEX.test(event.key) &&
                              !event.ctrlKey &&
                              event.code &&
                              event.code != "Backspace" &&
                              !event.code.includes("Arrow") &&
                              !event.code.includes("Delete")
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={handleSubmit()}
                          onChange={(event) => {
                            onChange(event);
                            setUsernameFieldValue(event);
                          }}
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                          InputLabelProps={{
                            classes: { focused: "hello-world", root: "world" },
                          }}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </div>
                  {error && <p className="error_block">{error}</p>}
                  {requestInProgress && (
                    <p className="please_wait_block">
                      Please wait &nbsp; &nbsp;{" "}
                      <CircularProgress color="inherit" size={30} />
                    </p>
                  )}

                  {!requestInProgress && (
                    <button
                      className="button-primary otp-button"
                      disabled={!username}
                      type="submit"
                      onClick={handleSubmit(async () => {
                        setRequestInProgress(true);
                        const userRegisterPayload = userRegisterPayloadTemplate(
                          {
                            first_name: lenderUserWarning?.firstName,
                            last_name: lenderUserWarning?.lastName,
                            phone_number: currentCountryPrefix + username,
                            email: lenderUserWarning?.email,
                            communication_zip:
                              lenderUserWarning?.communicationZip,
                            communication_state:
                              lenderUserWarning?.communicationState,
                            communication_city:
                              lenderUserWarning?.communicationCity,
                            communication_address_line1:
                              lenderUserWarning?.communicationAddressLine1,
                            communication_address_line2:
                              lenderUserWarning?.communicationAddressLine2,
                          }
                        );
                        const instance = axios.create();
                        instance
                          .post(
                            environment.api_open_endpoint + "/open-api",
                            userRegisterPayload
                          )
                          .then((data) => {
                            if (data?.status === 200) {
                              setSeconds(90);
                              setShowKey("OTP");
                              setUsername(currentCountryPrefix + username);
                              signInOtpRequest(currentCountryPrefix + username);
                              setLenderUserWarning({
                                ...lenderUserWarning,
                                onlyPhoneExists: false,
                              });
                            }
                          })
                          .catch((err) => {
                            enqueueSnackbar("Something went wrong.!", {
                              variant: "error",
                            });
                          })
                          .finally(() => setRequestInProgress(false));
                      })}
                    >
                      Get One Time Passcode (OTP)
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
          {showKey === "ENTERPHONE" && (
            <div className="formContainMain h-100">
              <form className="overflow">
                <h1 style={{ maxWidth: "90%" }} className="res-modal-title">
                  Enter Your Existing Phone Number
                </h1>
                {/* <p style={{ display: "flex", margin: 0, alignItems: "center" }}>
                  Please enter the OTP sent to&nbsp; &nbsp;{" "}
                  {uuidLoader && <CircularProgress color="inherit" size={25} />}
                </p> */}
                <strong>
                  {lenderUserWarning?.phoneNumber?.replace(
                    /.(?=.{4,9}$)/g,
                    "*"
                  )}
                </strong>
                <div className="tabs">
                  <div className="mt-20">
                    <FormControl style={{ width: "20%", marginRight: "10px" }}>
                      <InputLabel id="prefix-login-label">Code</InputLabel>
                      <Select
                        disabled={
                          environment.country_prefix.is_country === "false"
                        }
                        labelId="prefix-login-label"
                        id="prefix-login-label-select"
                        label="Prefix"
                        value={currentCountryPrefix}
                        onChange={(event) =>
                          setCurrentCountryPrefix(event.target.value)
                        }
                      >
                        {countryPrefixes.map((code) => (
                          <MenuItem value={code} key={code}>
                            {code}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Controller
                      name="phone_number"
                      control={control}
                      rules={{
                        required: "Phone is required.",
                        validate: (value) =>
                          handleMobileValidate(value)
                            ? null
                            : "Please enter valid Phone number.",
                      }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <TextField
                          style={{ width: "75%", margin: 0 }}
                          id="phone_number"
                          name="phone_number"
                          label="Phone Number"
                          variant="outlined"
                          margin="normal"
                          color="secondary"
                          type="text"
                          value={value}
                          error={!!error}
                          placeholder="Enter your phone number"
                          onKeyDown={(event) => {
                            const NUMERIC_PLUS_REGEX = /^[0-9]+$/;
                            if (event.key === "Tab") {
                            } else if (
                              !NUMERIC_PLUS_REGEX.test(event.key) &&
                              !event.ctrlKey &&
                              event.code &&
                              event.code != "Backspace" &&
                              !event.code.includes("Arrow") &&
                              !event.code.includes("Delete")
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={handleSubmit()}
                          onChange={(event) => {
                            onChange(event);
                            setUsernameFieldValue(event);
                          }}
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                          InputLabelProps={{
                            classes: { focused: "hello-world", root: "world" },
                          }}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </div>
                  {error && <p className="error_block">{error}</p>}
                  {requestInProgress && (
                    <p className="please_wait_block">
                      Please wait &nbsp; &nbsp;{" "}
                      <CircularProgress color="inherit" size={30} />
                    </p>
                  )}

                  {!requestInProgress && (
                    <button
                      className="button-primary otp-button"
                      disabled={!username}
                      type="submit"
                      onClick={handleSubmit(() => {
                        if (
                          lenderUserWarning?.phoneNumber ===
                          currentCountryPrefix + username
                        ) {
                          setShowKey("OTP");
                          setSeconds(90);
                          setUsername(lenderUserWarning?.phoneNumber);
                          signInOtpRequest(lenderUserWarning?.phoneNumber);
                        } else {
                          enqueueSnackbar("This phone number is invalid !", {
                            variant: "error",
                          });
                        }
                        // setRequestInProgress(true);
                        // let usernameCalculated =
                        //   value == 1 ? username : currentCountryPrefix + username;
                        // signInOtpRequest(usernameCalculated);
                      })}
                    >
                      Request One Time Passcode (OTP)
                    </button>
                  )}
                </div>
              </form>
            </div>
          )}
        </>
        {requestInProgress && (
          <Stack
            sx={{
              width: "100%",
              color: "#fcbb18",
              position: "absolute",
              bottom: 0,
            }}
            spacing={2}
          >
            <LinearProgress color="inherit" />
          </Stack>
        )}
      </div>
      <Modal
        open={lenderUserWarning?.isOpen}
        onClose={onCloseLenderUserWarning}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="main_modal_bg"
      >
        <div className="main_modal">
          <div className="modal_bg">
            <div
              style={{ padding: "30px" }}
              className={`modal_header justify-end`}
            >
              <img src={logo2} alt="" className="modal_logo" />
            </div>
            <div className="p-40 model-contain-main-responsive modal_body">
              <div className="model-contain" id="model-insurance-marketplace">
                <p className="login-link-message text-center">
                  {lenderUserWarning?.message}
                </p>
                <div
                  style={{
                    textAlign: "center",
                    marginTop: "40px",
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                  }}
                >
                  {lenderUserWarning?.primaryBtn && (
                    <Button
                      className="view_button"
                      style={{ width: "105px", height: "50px" }}
                      onClick={() =>
                        handelLenderUserWarning(lenderUserWarning?.primaryBtn)
                      }
                    >
                      {lenderUserWarning?.primaryBtn}
                    </Button>
                  )}
                  {lenderUserWarning?.secondaryBtn && (
                    <Button
                      className="lender_user_cancel_btn view_button"
                      style={{ width: "105px", height: "50px" }}
                      onClick={() =>
                        handelLenderUserWarning(lenderUserWarning?.secondaryBtn)
                      }
                    >
                      {lenderUserWarning?.secondaryBtn}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Login;
